.orderConfirmationMainContainer {
    display: flex flex block;
    align-items: middle;
    margin: auto;
}
.orderConfiramtionHeader { 
    font-size: 28px;
}

.bibButtonStyle {
    margin-left: 15px;
}


.tableItemStyle {
    border: 2px solid #ddd;
    padding: 10px;
}

.tableStyle {
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 20px;
}