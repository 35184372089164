.nextButtonContainer {
  position: fixed;
  width: calc(100% - 3rem);
  z-index: 99;
  bottom: 0;
  padding: 1.5rem;
}

.biButton {
  position: relative;
  width: 100%;
}
