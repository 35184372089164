.bibHeaderContainer {
    display: flex;
    justify-content: space-between;
}

.bibCollectionHeader { 
    text-align: center;
    font-size: 28px;
    margin: 20px;
}

.descriptionContainer {
    margin-bottom: 20px;
}