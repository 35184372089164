@import "./variables.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background-primary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Remove uparrow and downarrow for input type number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
  border-style: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

.ant-table {
  overflow: auto;
}

.m0 {
  margin: 0;
}

.m0-b {
  margin-bottom: 0;
}

.m0-r {
  margin-right: 0;
}

.m0-t {
  margin-top: 0;
}

.m0-l {
  margin-left: 0;
}

.m1 {
  margin: 0.5rem;
}

.m1-b {
  margin-bottom: 0.5rem;
}

.m1-r {
  margin-right: 0.5rem;
}

.m1-t {
  margin-top: 0.5rem;
}

.m1-l {
  margin-left: 0.5rem;
}

.m2 {
  margin: 1rem;
}

.m2-b {
  margin-bottom: 1rem;
}

.m2-r {
  margin-right: 1rem;
}

.m2-t {
  margin-top: 1rem;
}

.m2-l {
  margin-left: 1rem;
}

.p0 {
  padding: 0;
}

.p0-b {
  padding-bottom: 0;
}

.p0-r {
  padding-right: 0;
}

.p0-t {
  padding-top: 0;
}

.p0-l {
  padding-left: 0;
}

.p1 {
  padding: 0.5rem;
}

.p1-b {
  padding-bottom: 0.5rem;
}

.p1-r {
  padding-right: 0.5rem;
}

.p1-t {
  padding-top: 0.5rem;
}

.p1-l {
  padding-left: 0.5rem;
}

.p2 {
  padding: 1rem;
}

.p2-b {
  padding-bottom: 1rem;
}

.p2-r {
  padding-right: 1rem;
}

.p2-t {
  padding-top: 1rem;
}

.p2-l {
  padding-left: 1rem;
}

.p3 {
  padding: 1rem;
}

.p3-b {
  padding-bottom: 1.5rem;
}

.p3-r {
  padding-right: 1.5rem;
}

.p3-t {
  padding-top: 1.5rem;
}

.p3-l {
  padding-left: 1.5rem;
}

.center {
  text-align: center;
}

.fallback-ui,
.server-error-ui {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* to make ant descriptions responsive  */
.ant-descriptions-view {
  overflow: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.max-1080 {
  max-width: 1080px;
  margin: auto;
}

.width100 {
  width: 100%;
}

.width50 {
  width: 50%;
}

.minHeight100 {
  min-height: calc(100vh - 64px);
}

.link-color {
  color: var(--color-link-color);
}

.bg-white {
  background-color: var(--color-background-primary) !important;
}

.fw500 {
  font-weight: 500;
}

/* Loader css */
.loader-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: black black transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #ff3d00 #ff3d00;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 32px;
  height: 32px;
  border-color: black black transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.text-black {
  color: rgba(0, 0, 0, 0.88);
}

/* For iframe breaking the layout fix */

.iframe-container iframe {
  width: 100%;
  height: auto;
}

.danger {
  color: #dc3545;
}

.success {
  color: #28a745;
}
