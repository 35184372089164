.paymentsFormContainer {
  background-color: white;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  min-height: calc(100vh - 64px);
}

.paymentsForm {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
