.header {
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
  background-color: white !important;
  box-shadow: var(--box-shadow);
  padding-inline: 1.5rem !important;
}

.footer {
  background-color: white !important;
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  width: 100%;
  margin: auto;
  padding-bottom: 4.5rem !important;
  justify-content: space-between;
}

.logo {
  height: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  object-fit: contain;
}

@media screen and (min-width: 767px) {
  .footer {
    padding-bottom: 1.5rem !important;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2rem;
  }
}
